import { Link, NavLink } from "react-router-dom";
import { Container, Menu } from "semantic-ui-react";
import logo from '../logo.svg'

export default function NavBar() {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <Link to='/'>
          <h2>AnuSoft.in</h2>
        </Link>
        <ul className="nav-links">
          <li>
            <Link to='/contact'>Contact us</Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}
