import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Zoom } from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'


export default function Software({ id, appName, desc, images }) {
    const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/anusoft-88ad2.appspot.com/o/images%2f';

    return (
        <article className='card'>
            <div className='cocktail-footer'>
                <h3>{appName}</h3>
                <h4>{desc}</h4>
                <Zoom scale={1.4} indicators={true}>
                    {images.map((each, index) => (
                        <div key={index} style={{ width: "100%" }}>
                            <img style={{ objectFit: "cover", width: "100%", borderWidth: '1px', borderStyle: 'solid', borderColor:'darkgray', borderRadius:'5px'}} alt="Slide Image" src={baseUrl + each.url} />
                            <div style={{ fontWeight: 'bold' }}>{each.cap}</div>
                            <p>{each.desc}</p>
                        </div>
                    ))}
                </Zoom>
                <Link to={`/software/${id}`} className='btn btn-primary btn-details'>
                    View All Features
                </Link>
            </div>
        </article>
    )
}
