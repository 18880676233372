import React from 'react'

export default function ContactUsPage() {
  const email = 'anusoft22@gmail.com'
  const phone = '7041447719'
  return (

    <section className='section about-section'>
      <h1 className="section-title">Contact Us</h1>
      <article className='cocktail'>
        <div className='drink' style={{ margin: '2em' }}>
          <p>
            <div>
              <span className='drink-data'>email :</span> <a href={'mailto: ' + email}>{email}</a>
            </div>
            <br />
            <div>
              <span className='drink-data'>phone :</span> <a href={'tel: ' + phone}>{phone}</a>
            </div>
          </p>
        </div>
      </article>
    </section>
  )
}
