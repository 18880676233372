import React, { useState, useContext, useEffect } from 'react'
import { useCallback } from 'react'
import Softwares from './Softwares'

const url = 'https://www.thecocktaildb.com/api/json/v1/1/search.php?s='
const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [softwares, setSoftwares] = useState([])

  const fetchSoftwares = () => {
    setLoading(true)
    console.log(Softwares)
    setSoftwares(Softwares)
    setLoading(false)
  }

  useEffect(() => {
    fetchSoftwares()
  }, [])

  return <AppContext.Provider value={{ loading, softwares }}>{children}</AppContext.Provider>
}
// make sure use
export const useGlobalContext = () => {
  return useContext(AppContext)
}

export { AppContext, AppProvider }
