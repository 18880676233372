import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { useParams, Link } from 'react-router-dom'
import Softwares from '../Softwares'

const SingleSoftware = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [software, setSoftware] = useState(null)

  const [isFullScreen, setIsFullScreen] = useState(false);
  const [fullScreenImage, SetFullScreenImage] = useState({})

  useEffect(() => {
    setLoading(true)
    function getSoftware() {
      let softwares = Softwares.filter((item) => item.id == id)
      return softwares.length > 0 ? softwares[0] : null
    }

    let currentSoftware = getSoftware()
    setSoftware(currentSoftware)
    setLoading(false)
  }, [id])

  if (loading) {
    return <Loading />
  }

  if (!software) {
    return <h2 className='section-title'>Software not found</h2>
  }

  const baseUrl = 'https://firebasestorage.googleapis.com/v0/b/anusoft-88ad2.appspot.com/o/images%2f';
  const { appName, desc, images } = software

  function handleFullScreen(image) {
    SetFullScreenImage(image)
    setIsFullScreen(true)
  }

  function handleExitFullScreen() {
    SetFullScreenImage('')
    setIsFullScreen(false)
  }

  return (
    <>
      {isFullScreen ?
        (
          <>
            <section className='section cocktail-section' style={{ backgroundColor: 'white' }}>
              <h2>{fullScreenImage.cap}</h2>
              <img style={{ objectFit: "contain", borderWidth: '1px', borderStyle: 'solid', borderColor: 'darkgray', borderRadius: '5px' }} alt="Slide Image" src={baseUrl + fullScreenImage.url} onClick={() => { handleExitFullScreen(); }} />
              <div style={{ color: 'black', textAlign: 'justify', margin: '2em', fontSize: '1.2em' }}>{fullScreenImage.desc}</div>
            </section>
          </>
        )
        : (<section className='section cocktail-section'>
          <Link to='/' className='btn btn-primary'>
            Back to Home
          </Link>
          <h2>{appName}</h2>
          <h4>{desc}</h4>
          <div className='cocktails-center'>

            {images.map((each, index) => (
              <article className='card' key={index}>
                <div className='cocktail-footer'>
                  <div key={index} style={{ width: "100%" }}>
                    <img style={{ objectFit: "cover", width: "100%", borderWidth: '1px', borderStyle: 'solid', borderColor: 'darkgray', borderRadius: '5px' }} alt="Slide Image" src={baseUrl + each.url} onClick={() => { handleFullScreen(each); }} />
                    <div style={{ fontWeight: 'bold', textAlign: 'left', marginTop:'2em' }}>{each.cap}</div>
                    <p style={{ textAlign: 'left', color: 'black' }}>{each.desc}</p>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </section>)}
    </>
  )
}

export default SingleSoftware
