const Softwares = [{
    id: 1,
    appName: 'Smart Salon',
    desc: 'The complete Salon & Spa management software',
    images: [
        {
            url: 'Appointment.png?alt=media&token=0cdd81b5-50a4-4351-b57b-9609811b85aa',
            cap: 'Appointment Scheduler',
            desc: 'Appointments can be scheduled for customers with any service provider for one or more services. The time can easily be extended through the graphical interface, and it can also be dragged and dropped to assign to another service provider. It can be edited at any time, and an invoice can be created directly from an appointment.'
        },
        {
            url: 'Invoice.png?alt=media&token=b15405b2-103f-4152-a536-cfbe5a90c85b',
            cap: 'Invoice',
            desc: 'An invoice can be created for the services, products, or packages sold to the customer. It will also track which service provider (employee) has provided this, and he/she can get commission as per the sales he/she has done. You can also track how much product was used for a service in order to manage product usage.'
        },
        {
            url: 'Purchase.png?alt=media&token=042685f0-75fe-4c55-8480-3d48a5ae5adf',
            cap: 'Purchase',
            desc: 'Here, you can enter all of the invoices you\'ve received from your vendors for your purchases. The product stock will be automatically adjusted with your purchases.'
        },
        {
            url: 'Enquiry.png?alt=media&token=0f9acafe-66b6-4b04-83c6-d3b71d812c63',
            cap: 'Enquiry',
            desc: 'In Smart Salon, you can track any new customer who simply inquires about a service or product and states that he or she may or will return later. You can also track when the customer will visit, and the Smart Salon will remind you when to follow up. You can also track how the customer heard about your salon (e.g., from friends, Facebook, ads, etc.), so that you know from where you are getting the most customers.'
        },
        {
            url: 'Expense.png?alt=media&token=dcb40be7-88f5-498a-8dac-7ed1e6224c78',
            cap: 'Expense Management',
            desc: 'You can also track all the in-salon expenses, so that you can reconcile every penny.'
        },
        {
            url: 'Package.png?alt=media&token=615d88bb-10fc-446d-aac1-7069f19907e3',
            cap: 'Package',
            desc: 'You can define a package, and sell that to the customer. e.g., you can create a Diwali package, with a set of services at a discounted price.'
        },
        {
            url: 'Wallet.png?alt=media&token=567f2c74-0cd6-49f7-8916-fce615bffbe6',
            cap: 'Wallet',
            desc: 'You can create Wallet Account for your customers, who can deposit money on it and get a discounted rate for the services.'
        },
        {
            url: 'Service.png?alt=media&token=55ef8387-ac47-4c04-8902-f600c5e8d73e',
            cap: 'Service',
            desc: 'Here you can manage all the services that your Salon or Spa provides, including their prices, categories, etc.'
        },
        {
            url: 'Product.png?alt=media&token=042fffc3-83bd-4cab-92b6-54c333c3fef9',
            cap: 'Product',
            desc: 'Here, all the products to sell or purchase can be managed. The system will also track the stock, and notify you if the stock of any product is less than the minimum stock required for the product. You can also import all of your products from an Excel spreadsheet, so that you do not need to manually enter all the data.'
        },
        {
            url: 'Customers.png?alt=media&token=96f95259-613e-4847-80ab-edb66a5a88d5',
            cap: 'Customers',
            desc: 'You can manage information about all of your customers, and filter them by name, phone no., and pending due.'
        },
        {
            url: 'Vendor.png?alt=media&token=a0f0905b-9c21-48df-9723-89c14cf21a61',
            cap: 'Vendors',
            desc: 'You can manage information about all of your vendors.'
        },
        {
            url: 'TeamMember.png?alt=media&token=b500d144-2bb0-43ce-9af3-ca1b6d6b2859',
            cap: 'Team Member',
            desc: 'Here you can manage all the details for your employees, including their ID proofs and Photos.'
        },
        {
            url: 'User.png?alt=media&token=c2485bc3-c49e-46a4-875e-54e9013f1075',
            cap: 'User',
            desc: 'You can create unlimited number of users, who can access Smart Salon, accordingly to their access rights.'
        },
        {
            url: 'Dashboard.png?alt=media&token=9df703ab-157a-4ad5-bdee-3ba78443541a',
            cap: 'Dashboard',
            desc: 'You can get different statistical information in the dashboard for today, for a range of days, or for all days.'
        },
        {
            url: 'Holiday.png?alt=media&token=17a5ab5b-7f28-4fe1-95b0-d83957be0422',
            cap: 'Holiday Management',
            desc: 'You can add Holidays, so that appointment is not scheduled on that day.'
        },
        {
            url: 'Attendence.png?alt=media&token=f348da99-cd10-49fa-81b8-cff0027eebe8',
            cap: 'Attendance Management',
            desc: 'Attendance can be easily maintained for all the employees.'
        },
        {
            url: 'Backup.png?alt=media&token=6f3d5608-8527-4068-984f-49607eeca210',
            cap: 'Data Backup',
            desc: 'Your business data can be safely and securely backed up on your own Google Drive account. Even we cannot access your data unless you purposefully share it with us.'
        },
        {
            url: 'CashBalanceReport.png?alt=media&token=f359d67e-8389-45db-9820-4d6a10682339',
            cap: 'Cash Balance Report',
            desc: 'In this report, you can get details of all incomes or expenses in each payment mode (i.e., Cash, UPI, Check, etc.) for today, for a range of days, or for all days.'
        },
        {
            url: 'Salary%20Slip.png?alt=media&token=c2877bd8-8daf-4f28-8dea-58c884f82bec',
            cap: 'Salary Slip',
            desc: 'You can generate salary slips for all of your employees with all the salary details.'
        },
        {
            url: 'SalaryProcessing.png?alt=media&token=0bf73c0d-978f-4865-ad67-ca158d2d3210',
            cap: 'Salary Processing',
            desc: 'You can do monthly salary processing for all of your employees based on their attendance, commission, etc.'
        },
        {
            url: 'Settings.png?alt=media&token=9a0cf26f-54f5-4de1-a820-15856259147d',
            cap: 'Settings',
            desc: 'Here you can manage all the configuration to customize the software for your Salon or Spa.'
        },
        {
            url: 'TeamMemberReport.png?alt=media&token=c4228686-5d0c-4b24-8908-f3bba8c7330f',
            cap: 'Team Member Report',
            desc: 'You can get the report on the productivity of your employees, e.g., how much service they have provided, or how many products they have sold, how much commission they have earned, etc.'
        },
    ]
}]

export default Softwares;


// Holiday.png
// Invoice.png
// Invoices.png
// Package.png
// Product.png
// Purchase.png
// Salary Slip.png
// SalaryProcessing.png
// Service.png
// Settings.png
// TeamMember.png
// TeamMemberReport.png
// User.png
// Vendor.png
// Wallet.png
