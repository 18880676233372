import { Header, Segment } from "semantic-ui-react";

export default function VideoTutorials() {
    return (
        <>
            <Segment placeholder>
                <Header icon>
                    <Header size='huge'>Coming Soon!!!</Header>
                </Header>
            </Segment>
        </>
    )
}
