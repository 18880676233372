import React from 'react'
import Loading from './Loading'
import { useGlobalContext } from '../Context'
import Software from './Software';

const SoftwareList = () => {
    const { softwares, loading } = useGlobalContext();

    if (loading) {
        return <Loading />
    }

    if (softwares.length < 1) {
        return (<h2 className='section-title'>
            No records found
        </h2>)
    }

    return (
        <section className='section'>
            <h2 className='section-title'>
                Our Softwares
            </h2>
            <div className='cocktails-center'>
                {softwares.map((item) => {
                    return <Software key={item.id} {...item} />

                })}
            </div>
        </section>
    )
}

export default SoftwareList
