import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import NavBar from './components/NavBar'
import HomePage from './pages/HomePage';
import VideoTutorials from './pages/VideoTutorials';
import ErrorPage from './pages/ErrorPage';
import ContactUsPage from './pages/ContactUsPage';
import SingleSoftware from './pages/SingleSoftware';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Switch>
        <Route exact path='/'>
          <HomePage />
        </Route>
        <Route path='/videotutorials'>
          <VideoTutorials />
        </Route>
        <Route path='/contact'>
          <ContactUsPage />
        </Route>
        <Route path='/software/:id'>
          <SingleSoftware />
        </Route>
        <Route path='*'>
          <ErrorPage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
