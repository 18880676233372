import { Button, Header, Icon, Segment } from 'semantic-ui-react'
import SoftwareList from '../components/SoftwareList';

export default function HomePage() {

  function downloadSmartSalon() {
    window.open('https://app.amitdas.net/SmartSalon/', '_blank');
  }
  function downloadSmartShop() {
    window.open('https://app.amitdas.net/SmartShop/', '_blank');
  }

  return (
    <>
      <SoftwareList />
    </>
  )
}
